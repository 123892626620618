@import '../node_modules/fullpage.js/dist/fullpage.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  max-width: 250vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
}

h6 sub{
  font-size: small;
  color: gray;
}

.App-con {
    position: relative;
    margin-top: 5px;
    padding-bottom: 5px;
}

.App-social{
  margin-left: 20px;
}

.App-link {
  color: #61dafb;
}

